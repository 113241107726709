@import './src/scss/variables';
@import './src/scss/app';

.widget__flash {
	&-container {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  cursor: pointer;
	  gap: 1rem;
	  user-select: none;
	  height: $max-height;

		&.error {
		  cursor: default;
		  pointer-events: none;

		  .active {
		    cursor: pointer;
		    pointer-events: auto;

		    &:hover {
		      opacity: $opacity;
		    }
		  }
		}

	  &.result {
	    cursor: default;
	    pointer-events: none;

	    &.inactive {
	      cursor: unset !important;
	    }

		  .active {
		    cursor: pointer;
		    pointer-events: auto;

		    &:hover {
		      opacity: $opacity;
		    }
		  }
	  }
	}

	&-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

		li {
			display: flex;
	    justify-content: center;
	    align-items: center;
	    gap: .5rem;

	    a {
				display: flex;
	      align-items: center;
	      gap: .5rem;
	      cursor: pointer;
		    pointer-events: auto;

		    &:hover {
		      opacity: $opacity;
		    }
	    }
		}
	}

	&-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
	}
}